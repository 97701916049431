import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddAlert from "@material-ui/icons/AddAlert";
import { cpfMask } from "js_utils/util.js";
import { TextField } from "@material-ui/core";
import Logout from "components/Logout";
import Snackbar from "components/Snackbar/Snackbar.js";
import { triggerBase64Download } from "common-base64-downloader-react";
const { formataValorReais } = require("../../js_utils/util");
const { getAutenticado, postAutenticado } = require("../../js_utils/util");
const {
  parcelasMaximas,
  estimaParcela,
  formatSQLDate,
  calculaParcelasPersonalizado,
  formataData,
} = require("../../js_utils/util");
const { API_URL } = require("../../consts");

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Emprestimo() {
  const classes = useStyles();
  const [etapa, setEtapa] = useState(1);
  const [clientes, setClientes] = useState([[]]);
  const [valorEmprestimo, setValorEmprestimo] = useState(0.0);
  const [clienteSelecionado, selecionaCliente] = useState({});
  const [temClientes, setTemClientes] = useState(false);
  const [maxParcelas, setMaxParcelas] = useState(10);
  const [parcelaEstimada, setParcelaEstimada] = useState(0);
  const [numParcelas, setNumParcelas] = useState(null);
  const [dadosEstabelecimento, setDadosEstabelecimento] = useState(null);
  const [propostaValida, setPropostaValida] = useState(false);
  const [dentroLimiteEstab, setDentroLimiteEstab] = useState(true);
  const [dentroLimiteCliente, setDentroLimiteCliente] = useState(true);
  const [dentroPropostaMinima, setDentroPropostaMinima] = useState(false);
  const [observacao, setObservacao] = useState("");
  const [logado, setLogado] = useState(true);
  const [propostaSelecionada, setPropostaSelecionada] = useState({});
  const [etapaConfirmacao, setEtapaConfirmacao] = useState(0); //1 - Cancelamento; 2 - Confirmacao
  const [emprestimoSelecionado, setEmprestimoSelecionado] = useState({});
  const [debitos, setDebitos] = useState(null);

  /* Evitar múltiplos envios em sequência */
  const [envioDisabled, setEnvioDisabled] = useState(false);
  const [cancelamentoDisabled, setCancelamentoDisabled] = useState(false);
  const [confirmacaoDisabled, setConfirmacaoDisabled] = useState(false);

  const cabecalhoProposta = [
    "Cliente",
    "Vlr. Financiado",
    "Parcelas",
    "Vlr. Parcela",
    "Data Submissão",
    "Observação",
    "Detalhes",
  ];
  const mudaValor = (e) => {
    e = e.replace(/\D/g, "");
    const val = parseFloat(e) || 0.0;
    setValorEmprestimo(val / 100.0);
    const max_parcs = parcelasMaximas(
      val / 100,
      dadosEstabelecimento.parcelaMinima,
      dadosEstabelecimento.taxaEstabelecimento,
      dadosEstabelecimento.numeroMaximoParcelas,
      dadosEstabelecimento.acrescimo,
    );
    setMaxParcelas(max_parcs);
    setNumParcelas(max_parcs);
  };
  const etapaConfirmacaoProposta = (objProposta) => {
    setPropostaSelecionada(objProposta);
    setEtapa(5);
  };
  const etapaDetalhamentoEmprestimo = (objEmprestimo) => {
    setEmprestimoSelecionado(objEmprestimo);
    setEtapa(6);
  };
  const confirmaProposta = async (propID) => {
    setConfirmacaoDisabled(true);
    try {
      await postAutenticado(API_URL + "confirmaproposta", {
        propostaID: propID,
      });
      setCor("success");
      setMensagemErro("Empréstimo Confirmado!");
      setEtapa(1);
      showNotification();
      carregaPropostas();
      carregaEmprestimos();
      carregaClientes();
    } catch (err) {
      setCor("danger");
      if (err.response) setMensagemErro(err.response.data.mensagem);
      else setMensagemErro("Algo deu errado. Tente mais tarde");
      setEtapa(1);
      showNotification();
    } finally {
      setConfirmacaoDisabled(false);
      setEtapaConfirmacao(0);
    }
  };

  const cancelaProposta = async (propID) => {
    setCancelamentoDisabled(true);
    try {
      await postAutenticado(API_URL + "cancelaproposta", {
        propostaID: propID,
      });
      setCor("success");
      setMensagemErro("Proposta Cancelada!");
      setEtapa(1);
      showNotification();
      carregaPropostas();
      carregaClientes();
    } catch (err) {
      setCor("danger");
      if (err.response) setMensagemErro(err.response.data.mensagem);
      else setMensagemErro("Algo deu errado. Tente mais tarde");
      setEtapa(1);
      showNotification();
    } finally {
      setCancelamentoDisabled(false);
      setEtapaConfirmacao(0);
    }
  };
  const carregaPropostas = async () => {
    try {
      const resultados = await getAutenticado(API_URL + "propostas");
      let propostas = [];
      resultados.data.propostas.forEach((item) => {
        const propAtual = {
          NOME: item.NOME,
          VALOR_FINANCIADO: item.VALOR_FINANCIADO,
          NUMERO_PARCELAS: item.NUMERO_PARCELAS,
          VALOR_PARCELA: item.VALOR_PARCELA,
          DT_HORA_SUBMISSAO: item.DT_HORA_SUBMISSAO,
          TAXA_BOLETO: item.TAXA_BOLETO,
          TAXA_JUROS: item.TAXA_JUROS,
          OBSERVACAO: item.OBSERVACAO,
          ID: item.ID,
        };
        propostas.push([
          item.NOME,
          formataValorReais(item.VALOR_FINANCIADO),
          item.NUMERO_PARCELAS + "x",
          formataValorReais(item.VALOR_PARCELA),
          formatSQLDate(item.DT_HORA_SUBMISSAO),
          item.OBSERVACAO,
          <Button
            color="info"
            key={item.ID}
            onClick={() => etapaConfirmacaoProposta(propAtual)}
          >
            Detalhes
          </Button>,
        ]);
      });
      setDadosPropostas(propostas);
    } catch (err) {
      console.log(err.message);
      setCor("danger");
      if (err.response) setMensagemErro(err.response.data.mensagem);
      else setMensagemErro("Algo deu errado. Tente mais tarde");
      showNotification();
    }
  };

  const carregaEmprestimos = async () => {
    try {
      const resultados = await getAutenticado(API_URL + "emprestimos");
      let emprestimos = [];
      resultados.data.propostas.forEach((item) => {
        const propAtual = {
          ID: item.ID,
          NOME: item.NOME,
          VALOR_FINANCIADO: item.VALOR_FINANCIADO,
          NUMERO_PARCELAS: item.NUMERO_PARCELAS,
          VALOR_PARCELAS: item.VALOR_PARCELAS,
          VENCIMENTO: item.proximo,
          SALDO: item.saldo,
          OBSERVACAO: item.OBSERVACAO,
          parcelas: item.parcelas,
        };
        if (propAtual.VENCIMENTO != null)
          emprestimos.push([
            item.ID,
            item.NOME,
            formataValorReais(item.VALOR_FINANCIADO),
            formataValorReais(item.VALOR_PARCELAS),
            formatSQLDate(item.proximo),
            formataValorReais(item.saldo),
            <Button
              color="info"
              key={item.ID}
              onClick={() => etapaDetalhamentoEmprestimo(propAtual)}
            >
              Detalhes
            </Button>,
          ]);
      });
      setDadosEmprestimos(emprestimos);
    } catch (err) {
      console.log(err.message);
      setCor("danger");
      if (err.response) setMensagemErro(err.response.data.mensagem);
      else setMensagemErro("Algo deu errado. Tente mais tarde");
      showNotification();
    }
  };
  const iniciaDownloadProposta = async (id) => {
    const resultados = await getAutenticado(API_URL + "contratoproposta/" + id);
    if (resultados.data.sucesso)
      triggerBase64Download(resultados.data.mensagem, "contrato" + id);
    else {
      setCor("error");
      setMensagemErro("Problema ao carregar o contrato");
      showNotification();
    }
  };
  useEffect(carregaPropostas, []);
  useEffect(carregaEmprestimos, []);
  const carregaClientes = async () => {
    try {
      const resultados = await getAutenticado(API_URL + "getClientes");
      const clis = resultados.data.data.map((item) => {
        return {
          CPF: cpfMask(item.CPF),
          NOME: item.NOME,
          LOGRADOURO: item.LOGRADOURO + ", num° " + item.NUMERO,
          CIDADE_UF: item.CIDADE + "/" + item.SIGLA,
          LIMITE: item.LIMITE,
          ID: item.ID,
        };
      });
      if (!clis || clis.length < 1) {
        setTemClientes(false);
      } else {
        setTemClientes(true);
        setClientes(clis);
        selecionaCliente(clis[0]);
        setDebitos(null);
      }
    } catch (err) {
      console.log(err.message);
      setLogado(false);
      setClientes([[]]);
    }
  };
  const carregaDadosEstabelecimento = async () => {
    try {
      const resultados = await getAutenticado(API_URL + "meusdados");
      setDadosEstabelecimento(resultados.data);
    } catch (err) {
      console.log(err.message);
      setLogado(false);
    }
  };
  const geraListaParcelas = () => {
    let items = [];
    for (let i = 1; i <= maxParcelas; i++)
      items.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>,
      );
    return items;
  };
  const cabecalhoEmprestimo = [
    "Proposta",
    "Cliente",
    "Vlr. Financiado",
    "Vlr. Total Parcelas",
    "Próximo Vencimento",
    "Saldo a Pagar",
    "Detalhes",
  ];
  const [dadosPropostas, setDadosPropostas] = useState([[]]);
  const [dadosEmprestimos, setDadosEmprestimos] = useState([[]]);
  useEffect(carregaClientes, []);
  useEffect(carregaDadosEstabelecimento, [etapa]);

  useEffect(() => {
    if (!dadosEstabelecimento || !clienteSelecionado) {
      setPropostaValida(false);
      return;
    }
    const propMinima = dadosEstabelecimento.emprestimoMinimo <= valorEmprestimo;
    const limCliente = clienteSelecionado.LIMITE >= valorEmprestimo;
    const limEstab =
      dadosEstabelecimento.limiteEstabelecimento >= valorEmprestimo;

    setDentroLimiteCliente(limCliente);
    setDentroLimiteEstab(limEstab);
    setDentroPropostaMinima(propMinima);
    setPropostaValida(propMinima && limCliente && limEstab && !debitos);

    const parcEst = estimaParcela(
      valorEmprestimo,
      numParcelas,
      dadosEstabelecimento.taxaEstabelecimento,
      dadosEstabelecimento.acrescimo,
      dadosEstabelecimento.iof,
    );
    setParcelaEstimada(parcEst);
  }, [numParcelas, dadosEstabelecimento, valorEmprestimo, clienteSelecionado]);

  const enviaProposta = async () => {
    setEnvioDisabled(true);
    try {
      const resposta = await postAutenticado(API_URL + "simula", {
        cpf: clienteSelecionado.CPF,
        valor: valorEmprestimo,
        parcelas: numParcelas,
        observacao: observacao,
      });
      if (resposta.data.aceita) {
        carregaPropostas();
        setMensagemErro("Proposta Aceita! Favor confirmar");
        setCor("success");
        setEtapa(1);
        showNotification();
      }
    } catch (err) {
      setCor("danger");
      if (err.response) setMensagemErro(err.response.data.mensagem);
      else setMensagemErro("Erro no servidor. Tente mais tarde");
      showNotification();
    } finally {
      setEnvioDisabled(false);
    }
    carregaClientes();
  };

  //snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };

  return logado ? (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {etapa == 1 ? (
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Recargas e Cobranças</h4>
              {/* <p className={classes.cardCategoryWhite}>
                Propostas ainda não efetivadas
              </p> */}
            </CardHeader>
            <CardBody>
              {dadosPropostas.length > 0 && dadosPropostas[0].length > 0 ? (
                <Table
                  tableHeaderColor="primary"
                  tableHead={cabecalhoProposta}
                  tableData={dadosPropostas}
                />
              ) : (
                <h4>Você não tem nenhuma proposta pendente de confirmação!</h4>
              )}{" "}
            </CardBody>
            <CardFooter>
              <Button color="success" onClick={() => setEtapa(2)}>
                Gerar Cobrança
              </Button>
            </CardFooter>
          </Card>
        ) : etapa == 2 ? (
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Cliente</h4>
              <p className={classes.cardCategoryWhite}>Selecione o Cliente </p>
            </CardHeader>{" "}
            <CardBody>
              <GridItem xs={12} sm={12} md={6}>
                <Autocomplete
                  style={{ marginTop: 10 }}
                  options={clientes}
                  getOptionLabel={(option) => option.NOME}
                  value={clienteSelecionado}
                  onChange={(event, value) => {
                    if (!value) {
                      setTemClientes(false);
                    } else {
                      setTemClientes(true);
                    }
                    selecionaCliente(value);
                  }}
                  id="seleciona-cliente"
                  renderInput={(params) => (
                    <TextField {...params} label="Cliente" />
                  )}
                  fullWidth={true}
                />
              </GridItem>
            </CardBody>
            <CardFooter>
              <span>
                <Button color="warning" onClick={() => setEtapa(1)}>
                  Cancelar
                </Button>
                <Button
                  disabled={!temClientes}
                  color="info"
                  onClick={async () => {
                    setEtapa(3);
                    console.log(clienteSelecionado);
                    const resposta = await getAutenticado(
                      API_URL + `inadimplencia/${clienteSelecionado.ID}`,
                    );

                    if (
                      resposta.data &&
                      resposta.data.resultado &&
                      resposta.data.resultado.length > 0
                    ) {
                      setCor("danger");
                      setMensagemErro("Cliente tem parcelas vencidas");
                      showNotification();
                      setDebitos(resposta.data.resultado);
                    } else {
                      setDebitos(null);
                    }
                  }}
                >
                  Próximo
                </Button>
              </span>
            </CardFooter>
          </Card>
        ) : etapa == 3 ? (
          debitos ? (
            <Card>
              <CardHeader color="primary">
                <h4>Parcelas em atraso</h4>
              </CardHeader>
              <CardBody>
                {debitos.map((prop) => {
                  return (
                    <span key={prop.PROPOSTA}>
                      <h4>Proposta #{prop.PROPOSTA}</h4>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={["Parcela", "Saldo", "Vencimento"]}
                        tableData={prop.parcelas.map((parc) => [
                          parc.NUMERO,
                          formataValorReais(parc.SALDO),
                          formataData(new Date(parc.VENCIMENTO)),
                        ])}
                      />
                    </span>
                  );
                })}
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={() => setEtapa(2)}>
                  {" "}
                  Voltar
                </Button>
              </CardFooter>
            </Card>
          ) : (
            <Card>
              <CardHeader color="primary">
                <h4>Valores da Proposta</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <TextField
                      label="Valor do empréstimo"
                      id="valor-emprestimo"
                      value={formataValorReais(valorEmprestimo)}
                      onChange={(e) => mudaValor(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    Número de Parcelas:{"  "}
                    <Select
                      style={{ marginTop: 10 }}
                      value={numParcelas || 1}
                      onChange={(e) => {
                        setNumParcelas(e.target.value);
                      }}
                      disabled={maxParcelas <= 0}
                    >
                      {geraListaParcelas()}
                    </Select>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      value={observacao}
                      onChange={(e) => {
                        setObservacao(e.target.value);
                      }}
                      label="Observação (opcional)"
                      multiline
                      fullWidth
                      rows={3}
                      rowsMax={10}
                    ></TextField>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <table>
                      <tbody>
                        <tr style={{ fontWeight: "bold" }}>
                          <td>Parcela Estimada</td>
                          <td style={{ textAlign: "right" }}>
                            {!isFinite(parcelaEstimada) ||
                            isNaN(parcelaEstimada) ||
                            parcelaEstimada <= 0
                              ? "-"
                              : formataValorReais(parcelaEstimada)}
                          </td>
                        </tr>
                        <tr>
                          <td>Limite do Cliente</td>
                          <td style={{ textAlign: "right" }}>
                            {formataValorReais(clienteSelecionado.LIMITE)}
                          </td>
                          <td>
                            {dentroLimiteCliente ? (
                              " "
                            ) : (
                              <span style={{ color: "red" }}>
                                {"Valor acima do limite"}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Limite do Estabelecimento</td>
                          <td style={{ textAlign: "right" }}>
                            {formataValorReais(
                              dadosEstabelecimento.limiteEstabelecimento,
                            )}
                          </td>
                          <td>
                            {dentroLimiteEstab ? (
                              " "
                            ) : (
                              <span style={{ color: "red" }}>
                                {"Valor acima do limite"}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Empréstimo Mínimo</td>
                          <td style={{ textAlign: "right" }}>
                            {formataValorReais(
                              dadosEstabelecimento.emprestimoMinimo,
                            )}
                          </td>
                          <td>
                            {dentroPropostaMinima ? (
                              " "
                            ) : (
                              <span style={{ color: "red" }}>
                                {"Valor abaixo do mínimo"}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Parcela Mínima</td>
                          <td style={{ textAlign: "right" }}>
                            {formataValorReais(
                              dadosEstabelecimento.parcelaMinima,
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <span>
                  <Button color="primary" onClick={() => setEtapa(2)}>
                    {" "}
                    Voltar
                  </Button>
                  <Button
                    color="info"
                    onClick={() => setEtapa(4)}
                    disabled={!propostaValida}
                  >
                    Próximo
                  </Button>
                </span>
              </CardFooter>
            </Card>
          )
        ) : etapa == 4 ? (
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Proposta </h4>
            </CardHeader>
            <CardBody>
              <table>
                <tbody>
                  <tr>
                    <td>Estabelecimento: </td>
                    <td>{dadosEstabelecimento.usuario.RAZAO_SOCIAL}</td>
                  </tr>
                  <tr>
                    <td>Cliente: </td>
                    <td>{clienteSelecionado.NOME}</td>
                  </tr>
                  <tr>
                    <td>Valor: </td>
                    <td>{formataValorReais(valorEmprestimo)}</td>
                  </tr>
                  <tr>
                    <td>Condição: </td>
                    <td>
                      {numParcelas} x {formataValorReais(parcelaEstimada)}
                    </td>
                  </tr>
                  <tr>
                    <td>Observação:</td>
                    <td>{observacao}</td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
            <CardFooter>
              <span>
                <Button color="primary" onClick={() => setEtapa(3)}>
                  Voltar
                </Button>
                <Button
                  color="success"
                  onClick={enviaProposta}
                  disabled={envioDisabled}
                >
                  Enviar Proposta
                </Button>
              </span>
            </CardFooter>
          </Card>
        ) : etapa === 5 ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">Proposta</CardHeader>
              <CardBody>
                <h4>Dados da Proposta:</h4>
                <table>
                  <tbody>
                    <tr>
                      <td>Cliente</td>
                      <td>{propostaSelecionada.NOME}</td>
                    </tr>
                    <tr>
                      <td>Valor Financiado</td>
                      <td style={{ textAlign: "right" }}>
                        {formataValorReais(
                          propostaSelecionada.VALOR_FINANCIADO,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Observação</td>
                      <td>{propostaSelecionada.OBSERVACAO}</td>
                    </tr>
                    <tr>
                      <td>Valor Parcela</td>
                      <td style={{ textAlign: "right" }}>
                        {formataValorReais(propostaSelecionada.VALOR_PARCELA)}
                      </td>
                    </tr>
                    <tr>
                      <td>Parcelas</td>
                      <td style={{ textAlign: "right" }}>
                        {propostaSelecionada.NUMERO_PARCELAS}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <th>Parcela</th>
                      <th>Vencimento</th>
                      <th>Valor</th>
                    </tr>
                    {calculaParcelasPersonalizado(
                      parseFloat(propostaSelecionada.VALOR_FINANCIADO),
                      parseFloat(propostaSelecionada.TAXA_JUROS),
                      parseInt(propostaSelecionada.NUMERO_PARCELAS),
                      0.01,
                      parseFloat(propostaSelecionada.TAXA_BOLETO),
                      parseFloat(dadosEstabelecimento.iof),
                    ).parcelas.map((item) => (
                      <tr key={item.parcela}>
                        <td>{item.parcela}</td>
                        <td>{formataData(item.vencimento)}</td>
                        <td style={{ textAlign: "right" }}>
                          {formataValorReais(item.valor)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
              <CardFooter>
                {etapaConfirmacao == 0 ? (
                  <span>
                    <Button color="primary" onClick={() => setEtapa(1)}>
                      Voltar
                    </Button>
                    <Button
                      color="success"
                      onClick={() => setEtapaConfirmacao(2)}
                    >
                      Confirmar Proposta
                    </Button>
                    <Button
                      color="danger"
                      onClick={() => setEtapaConfirmacao(1)}
                    >
                      Cancelar Proposta
                    </Button>
                  </span>
                ) : etapaConfirmacao == 1 ? (
                  <div>
                    <b>Tem certeza que deseja cancelar a proposta?</b>
                    <div>
                      {" "}
                      <Button
                        color="primary"
                        onClick={() => setEtapaConfirmacao(0)}
                      >
                        Não
                      </Button>
                      <Button
                        color="danger"
                        disabled={cancelamentoDisabled}
                        onClick={() => {
                          cancelaProposta(propostaSelecionada.ID);
                          setEtapaConfirmacao(0);
                        }}
                      >
                        Sim (Cancela Proposta)
                      </Button>
                    </div>
                  </div>
                ) : etapaConfirmacao == 2 ? (
                  <div>
                    <b>
                      Tem certeza que deseja confirmar a proposta e gerar o
                      empréstimo?
                    </b>
                    <div>
                      {" "}
                      <Button
                        color="primary"
                        onClick={() => setEtapaConfirmacao(0)}
                      >
                        Não
                      </Button>
                      <Button
                        color="success"
                        disabled={confirmacaoDisabled}
                        onClick={() => {
                          confirmaProposta(propostaSelecionada.ID);
                          setEtapaConfirmacao(0);
                        }}
                      >
                        Sim (Confirma Proposta)
                      </Button>
                    </div>
                  </div>
                ) : null}
              </CardFooter>
            </Card>
          </GridItem>
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4>Dados do Empréstimo</h4>
              </CardHeader>
              <CardBody>
                <table>
                  <tbody>
                    <tr>
                      <td>Cliente</td>
                      <td>{emprestimoSelecionado.NOME}</td>
                    </tr>
                    <tr>
                      <td>Valor Financiado</td>
                      <td style={{ textAlign: "right" }}>
                        {formataValorReais(
                          emprestimoSelecionado.VALOR_FINANCIADO,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Valor Parcela</td>
                      <td style={{ textAlign: "right" }}>
                        {formataValorReais(
                          emprestimoSelecionado.VALOR_PARCELAS,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Parcelas</td>
                      <td style={{ textAlign: "right" }}>
                        {emprestimoSelecionado.parcelas.length}
                      </td>
                    </tr>
                    <tr>
                      <td>Observação</td>
                      <td>{emprestimoSelecionado.OBSERVACAO}</td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <th>Parcela</th>
                      <th>Vencimento</th>
                      <th>Valor Parcela</th>
                      <th>Valor a Receber</th>
                    </tr>
                    {emprestimoSelecionado.parcelas.map((item) => (
                      <tr key={item.NUMERO}>
                        <td>{item.NUMERO}</td>
                        <td>{formatSQLDate(item.VENCIMENTO)}</td>
                        <td style={{ textAlign: "right" }}>
                          {formataValorReais(item.VALOR)}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {formataValorReais(item.SALDO)}
                        </td>
                      </tr>
                    ))}
                    <Button
                      color="info"
                      onClick={() =>
                        iniciaDownloadProposta(emprestimoSelecionado.ID)
                      }
                    >
                      Baixar Contrato
                    </Button>
                  </tbody>
                </table>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={() => setEtapa(1)}>
                  Voltar
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        )}
      </GridItem>
      {etapa === 1 ? (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Entradas PI Pix</h4>
              {/* <p className={classes.cardCategoryWhite}>
                Empréstimos feitos neste estabelecimento
              </p> */}
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={cabecalhoEmprestimo}
                tableData={dadosEmprestimos}
              />
            </CardBody>
          </Card>
        </GridItem>
      ) : null}
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </GridContainer>
  ) : (
    <Logout></Logout>
  );
}
