import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Logout from "components/Logout";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { TextField } from "@material-ui/core";

const { API_URL } = require("../../consts");
const {
  formataValorReais,
  getAutenticado,
  postAutenticado,
} = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function WalletComponent() {
  const classes = useStyles();
  const [dados, setDados] = useState({});
  const fetchData = async () => {
    try {
      const res = await getAutenticado(API_URL + "meusdados");
      setDados(res.data);
    } catch (error) {
      setLogado(false);
    }
  };
  let [logado, setLogado] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);
  return logado ? (
    <div>
      <Card>
        <CardHeader color="primary">
          <h5 className={classes.cardTitleWhite}>Wallet</h5>
        </CardHeader>
        {dados.contasInfo?.map((c) => (
          <Conta key={c.ID} dados={c} />
        ))}
      </Card>
    </div>
  ) : (
    <Logout></Logout>
  );
}

function Conta(prop) {
  const [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  const { dados } = prop;
  const [chavePix, setChavePix] = useState(dados.CHAVE_PIX);
  const [valor, setValor] = useState(0);
  const saldo = parseFloat(dados.SALDO);

  const mudaValor = (e) => {
    let valor = e.target.value;
    valor = valor.replace(/\D/g, "");
    valor = parseFloat(valor) || 0.0;
    valor = valor / 100.0;
    setValor(valor);
  };

  const saqueValido = valor <= saldo && valor > 0;

  const solicitaPix = async () => {
    if (saqueValido) {
      const body = {
        valor,
        chavePix,
        ID: dados.ID,
      };
      try {
        await postAutenticado(API_URL + "pix/solicitacao-saque", body);
        setCor("success");
        setMensagemErro("Solicitação realizada com sucesso");
        showNotification();
      } catch (error) {
        setCor("error");
        const message = error.response?.data?.mensagem || "Erro";
        setMensagemErro(message);
        showNotification();
      }
    } else {
      setCor("error");
      setMensagemErro("Valor inválido");
      showNotification();
    }
  };

  return (
    <Card>
      <CardHeader color="info">Wallet: {dados.NAME}</CardHeader>
      <CardBody>
        <TextField
          label="Chave Pix"
          value={chavePix}
          onChange={(e) => setChavePix(e.target.value)}
        />
        <TextField label="Saldo Atual" value={formataValorReais(saldo)} />
        <TextField
          label="Valor Saque"
          value={formataValorReais(valor)}
          onChange={mudaValor}
        />
        <Button
          color="success"
          label="Confirmar"
          disabled={!saqueValido}
          onClick={solicitaPix}
        >
          Solicitar Transferência
        </Button>
      </CardBody>
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </Card>
  );
}
