import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { TextField } from "@material-ui/core";
import Logout from "components/Logout";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
const axios = require("axios");
const { API_URL } = require("../../consts");
const { postAutenticado, putAutenticado } = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  campoSenha: {
    marginRight: "5px",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  let [RAZAO_SOCIAL, setRazaoSozcial] = useState("Indefinida");
  let [CNPJ, setCnpj] = useState("00000000000101");
  let [NOME, setNome] = useState("...");
  let [LOGIN, setLogin] = useState("...");
  const [chavePix, setChavePix] = useState("");
  const [chavePixInicial, setChavePixInicial] = useState(null);
  const [senha, setSenha] = useState("");
  const [senhaConf, setSenhaConf] = useState("");
  let [logado, setLogado] = useState(true);
  const [mudaSenha, setMudaSenha] = useState(1);
  const classes = useStyles();
  const handleAlteracao = (e) => {
    const { name, value } = e.target;
    if (name === "senha") setSenha(value);
    if (name === "confSenha") setSenhaConf(value);
  };
  useEffect(async () => {
    let resultado;
    try {
      const token = localStorage.getItem("vsToken");
      resultado = await axios.get(API_URL + "meusdados", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const usuario = resultado.data.usuario;
      setRazaoSozcial(usuario.RAZAO_SOCIAL);
      setCnpj(usuario.CNPJ);
      setNome(usuario.NOME);
      setLogin(usuario.LOGIN);
      if (resultado.data.contasInfo && resultado.data.contasInfo.length > 0) {
        const padrao = resultado.data.contasInfo.find((x) => x.IS_DEFAULT);
        setChavePix(padrao.CHAVE_PIX);
        setChavePixInicial(padrao.CHAVE_PIX);
      }
    } catch (error) {
      console.log(JSON.stringify(resultado));
      if (error.response && error.response.status == 401) setLogado(false);
    }
  }, []);

  const alterasenha = async () => {
    try {
      await postAutenticado(API_URL + "mudasenha", {
        password: senha,
        passwordConfirm: senhaConf,
      });
      setCor("success");
      setMensagemErro("Senha alterada!");
      showNotification();
    } catch (err) {
      setCor("danger");
      if (err.response) setMensagemErro(err.response.data.mensagem);
      else setMensagemErro("Algo deu errado. Tente mais tarde");
      showNotification();
    } finally {
      setMudaSenha(1);
    }
  };

  const alteraChavePix = async () => {
    try {
      await putAutenticado(API_URL + "estabelecimento/", {
        CHAVE_PIX: chavePix,
      });
      setCor("success");
      setMensagemErro("Chave Pix alterada!");
      showNotification();
    } catch (err) {
      setCor("danger");
      if (err.response) setMensagemErro(err.response.data.mensagem);
      else setMensagemErro("Algo deu errado. Tente mais tarde");
      showNotification();
    }
  };

  //snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  const fragmentoMudaSenha = (
    <GridItem xs={12} sm={12} md={8}>
      <Card>
        <CardHeader color="primary">
          <h5 className={classes.cardTitleWhite}>Alterar senha</h5>
        </CardHeader>
        <CardBody>
          <Button
            onClick={() => {
              setMudaSenha(2);
            }}
          >
            CLique aqui para alterar a sua senha de acesso
          </Button>
        </CardBody>
      </Card>
    </GridItem>
  );
  const fragmentoExecutaMudaSenha = (
    <GridItem xs={12} sm={12} md={8}>
      <Card>
        <CardHeader color="primary">
          <h5 className={classes.cardTitleWhite}>Alterar senha</h5>
        </CardHeader>
        <CardBody>
          <TextField
            type="password"
            className={classes.campoSenha}
            label="Nova senha"
            name="senha"
            onChange={handleAlteracao}
          />

          <TextField
            type="password"
            className={classes.campoSenha}
            label="Confirma senha"
            name="confSenha"
            onChange={handleAlteracao}
          />
          <Button onClick={alterasenha}> Alterar senha</Button>
        </CardBody>
      </Card>
    </GridItem>
  );
  return logado ? (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h5 className={classes.cardTitleWhite}>{RAZAO_SOCIAL}</h5>
              <p className={classes.cardCategoryWhite}>CNPJ: {CNPJ}</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <h3>Razão Social:</h3>
                  <h5>{RAZAO_SOCIAL}</h5>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <h3>CNPJ:</h3>
                  <h5>
                    {CNPJ.replace(
                      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      "$1.$2.$3/$4-$5"
                    )}
                  </h5>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <h3>Usuário:</h3>
                  <h5>{NOME}</h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <h3>Login:</h3>
                  <h5>{LOGIN}</h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <h3>Chave Pix:</h3>
                  <TextField
                    value={chavePix}
                    onChange={(e) => setChavePix(e.target.value)}
                  />
                  <Button
                    onClick={alteraChavePix}
                    color="success"
                    disabled={chavePix === chavePixInicial}
                  >
                    {" "}
                    Alterar Chave
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {mudaSenha == 1 ? fragmentoMudaSenha : fragmentoExecutaMudaSenha}
      </GridContainer>
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  ) : (
    <Logout></Logout>
  );
}
