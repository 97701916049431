/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Store from "@material-ui/icons/Store";
// import People from "@material-ui/icons/People";
// import Note from "@material-ui/icons/Note";
import Dev from "@material-ui/icons/DeveloperMode";
//import Print from "@material-ui/icons/Print";
import Money from "@material-ui/icons/AttachMoney";
// import Coin from "@material-ui/icons/AccountBalanceWallet";
import Signout from "@material-ui/icons/ExitToApp";
// core components/views for Admin layout
import MeusDados from "views/MeusDados/MeusDados.js";
import Emprestimo from "views/Emprestimo/Emprestimo";
// import Clientes from "views/Clientes/Clientes";
// import ContasReceber from "views/Contas/ContasReceber";
import Logout from "components/Logout";
// import MeusRepasses from "views/MeusRepasses/MeusRepasses";
import WalletComponent from "views/Wallet/WalletComponent";
const dashboardRoutes = [
  {
    path: "/wallet",
    name: "Wallet",
    rtlName: "قائمة الجدول",
    icon: Money,
    component: WalletComponent,
    layout: "/admin",
  },
  {
    path: "/emprestimo",
    name: "Api Pix",
    rtlName: "قائمة الجدول",
    icon: Dev,
    component: Emprestimo,
    layout: "/admin",
  },
  // {
  //   path: "/clientes",
  //   name: "Clientes",
  //   rtlName: "قائمة الجدول",
  //   icon: People,
  //   component: Clientes,
  //   layout: "/admin",
  // },
  // {
  //   path: "/contas",
  //   name: "Parcelas Cliente",
  //   rtlName: "قائمة الجدول",
  //   icon: Note,
  //   component: ContasReceber,
  //   layout: "/admin",
  // },
  // {
  //   path: "/repasses",
  //   name: "Repasses",
  //   rtlName: "قائمة الجدول",
  //   icon: Coin,
  //   component: MeusRepasses,
  //   layout: "/admin",
  // },
  {
    path: "/user",
    name: "Meus Dados",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Store,
    component: MeusDados,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Sair",
    rtlName: "إخطارات",
    icon: Signout,
    component: Logout,
    layout: "/admin",
  },
];

export default dashboardRoutes;
